import React, { useEffect, useState } from "react";
import { Route } from "react-router-dom";
// Custom imports
import {
  userIsAuthenticated,
  logOut,
  passwordIsTemporary
} from "../../services/Authentication";

import {fetchDebtSettlementApi,fetchGatewayApi} from "../../services/FetchApi";
import replaceNullsWithSpace from "../../helpers/ReplaceNullsWithSpace";
import formatPhoneNumbers from "../../helpers/FormatPhoneNumbers";
import getApiEnvironmentConfig from "../../helpers/GetApiEnvironmentConfig";
import getApiGatewayConfig from "../../helpers/GetApiGatewayConfig";
import { CurrentUserContext } from "../../context/CurrentUser";
import{ ChargeContinuationContext } from "../../context/ChargeContinuation"

export default function PrivateRoute(props) {
  const { component: Component, temporary: Temporary, ...rest } = props;
  const [currentUser, setCurrentUser] = useState({
    firstName: "",
    lastName: "",
    middleInitial: "",
    phoneNumber: "",
    emailAddress: "",
    line1: "",
    line2: "",
    city: "",
    state: "",
    zipcode: "",
    contactFirst: "",
    contactMiddle: "",
    contactLast: ""
  });
  const [chargeContinuationData, setChargeContinuationData] =useState()

  const fetchCurrentUser = async () => {
    const clientAddressesEndpoint = `${getApiEnvironmentConfig()}/api/clients/${
      sessionStorage.AccountId
    }/addresses`;
    const clientAddressResponse = await fetchDebtSettlementApi(
      "GET",
      clientAddressesEndpoint
    );
    const addressdata = await clientAddressResponse.data[0];

    const clientEndpoint = `${getApiEnvironmentConfig()}/api/clients/${
      sessionStorage.AccountId
    }`;
    const clientEndpointResponse = await fetchDebtSettlementApi("GET", clientEndpoint);
    let personalData = await clientEndpointResponse.data;
    personalData.phoneNumber = formatPhoneNumbers(personalData.phoneNumber);

    const clientContactsEndpoint = `${getApiEnvironmentConfig()}/api/clients/${
      sessionStorage.AccountId
    }/contacts`;
    const clientContactsResponse = await fetchDebtSettlementApi(
      "GET",
      clientContactsEndpoint
    );
    // Have to do it this way, because properties are the same with addressdata.
    const contactFirst = await clientContactsResponse.data[0].firstName;
    const contactMiddle = await clientContactsResponse.data[0].middleInitial;
    const contactLast = await clientContactsResponse.data[0].lastName;
    const contactData = {
      contactFirst: contactFirst,
      contactMiddle: contactMiddle,
      contactLast: contactLast
    };

    const companyEndpoint = `${getApiEnvironmentConfig()}/api/companies/${
      personalData.companyId
    }`;
    const companyEndpointResponse = await fetchDebtSettlementApi("GET", companyEndpoint);
    const policyGroupOfTheClient = await companyEndpointResponse.data.companyPolicyData.find(
      companyPolicy =>
        companyPolicy.policyGroupId === personalData.policyGroupId
    );

    // Combine objects
    const data = {
      ...addressdata,
      ...personalData,
      ...contactData,
      ...policyGroupOfTheClient
    };
    replaceNullsWithSpace(data);
    setCurrentUser(data);
  };
  
  // Getting Charge Continuation User data to Dispaly banner
  const fetchChargeContinuation = () => {
    const api = `${getApiGatewayConfig()}/Charges/clients/${sessionStorage.AccountId}/chargecontinuation`;
    fetchGatewayApi("GET", api)
      .then((response) => {
        if (response.status === 200) {
          setChargeContinuationData(response)
        } else {
          //fail silently
        }
      })
      .catch((error) => {
        //fail silently
      });
  };

  useEffect(() => {
    fetchCurrentUser();
    fetchChargeContinuation()
  }, []);

  if (passwordIsTemporary()) {
    return (
      <CurrentUserContext.Provider value={currentUser}>
       <ChargeContinuationContext.Provider value={chargeContinuationData}>
        <Route
          {...rest}
          render={() =>
            userIsAuthenticated() === true ? <Temporary /> : logOut()
          }
        />
        </ChargeContinuationContext.Provider>
      </CurrentUserContext.Provider>
    );
  } else {
    return (
      <CurrentUserContext.Provider value={currentUser}>
         <ChargeContinuationContext.Provider value={chargeContinuationData}>
        <Route
          {...rest}
          render={props =>
            userIsAuthenticated() === true ? <Component {...props} /> : logOut()
          }
        />
       </ChargeContinuationContext.Provider>
      </CurrentUserContext.Provider>
    );
  }
}
